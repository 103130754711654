<template>
    <div class="container-fluid min-vh-100 d-flex align-items-center justify-content-center">
        <div class="row w-100">
            <div class="col-12">
                <router-view class="mx-auto my-4"/>
                <div class="mt-3 text-center text-white">
                    <p class="mb-2">
                        <span>{{ $t('dontHaveAccount') }}</span>
                        <router-link class="color-cyan text-decoration-none" to="/auth/register">
                            {{ $t('signUp') }}
                        </router-link>
                    </p>
                    <p class="mb-2">
                        <router-link class="color-cyan text-decoration-none" to="/presentation">
                            {{ $t('watchPresentation') }}
                        </router-link>
                    </p>
                    <p class="mb-2"><a
                        :class="$i18n.locale === 'ru' ? 'text-white text-decoration-none' : 'color-cyan text-decoration-none'"
                        @click="changeLocale('ru')">RU</a> | <a
                        :class="$i18n.locale === 'en' ? 'text-white text-decoration-none' : 'color-cyan text-decoration-none'"
                        @click="changeLocale('en')">EN</a></p>
                    <p>© inFIBO, 2013 - {{ new Date().getFullYear() }}</p>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import {changeLocale} from '@/plugins/i18n'

export default {
    name: 'Auth',
    i18n: {
        messages: {
            en: {
                dontHaveAccount: 'Don\'t have an account? ',
                signUp: ' Signup Now',
                watchPresentation: ' Watch our presentation ',
            },
            ru: {
                dontHaveAccount: 'У вас нет аккаунта? ',
                signUp: ' Зарегистрируйтесь',
                watchPresentation: ' Посмотрите нашу презентацию ',

            },
        }
    },
    methods: {
        changeLocale,
    }
}
</script>

<style scoped>
.container-fluid {
    background-image: url('./../../assets/img/auth-background.jpg');
    background-color: #ecf1f3;
    background-repeat: no-repeat;
    background-size: cover;
}
a:hover {
    color: white;
    cursor: pointer;
}
</style>
